.tab:hover {
    background-color: #2f4f4f;
    color: white;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding: 5px;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-content {
    background-color: #2f4f4f;
}

.ant-menu-submenu-selected {
    color: none;
}

/* .m:hover {
    border-right: 4px solid #40a9ff;
} */

.ant-menu-submenu-selected {
    color: rgb(67, 67, 67);
}

.ant-menu-submenu-selected:hover .ant-menu-submenu-active {
    color: rgb(67, 67, 67);
}

/* .ant-menu-submenu .ant-menu-submenu-active {
    color: green;
} */

.ant-menu:hover .ant-menu-light .ant-menu-root .ant-menu-inline {
    color: rgb(67, 67, 67);
}
