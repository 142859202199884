
#filtre {
    background-color: antiquewhite;
    /* height: 50;
    width: 100; */
}

.center-div {
    margin: 0 auto;
    /* width: 100px;  */
}

.centreVerticalement {
    display: inline-block;
    vertical-align: middle;
    float: none;
   }