.sous-tache {
    width: 250px;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.sous-tache:hover {
    background-color: #2f4f4f;
    width: 250px;
    border-radius: 10px;
    padding: 5px;
    color: white;
    margin-bottom: 5px;
    cursor: pointer;
}

.sous-tache:hover .btn {
    color: white;
}

.title {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    /* color */
}
.title:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
}

.responsable {
    margin: 2px;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.responsable:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    display: flex;
    flex-direction: row;
}

.echecance {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.echecance:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.project {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.project:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.statut {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.statut:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.description {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border: 1px dashed #d3d3d3;
}
.description:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.detailtache .ant-picker-input > input {
    font-size: 13px;
}

.detailtache .ant-picker-input > input::placeholder {
    color: darkred;
}

.detailtache .ant-input .placeholder {
    color: darkred;
}

.detailtache .ant-drawer-close:hover {
    color: white;
}

.detailtache .ant-drawer-close {
    color: white;
}

.detailtache .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
    border: none;
}

.detailtache .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background-color: white;
}

.detailtache .ant-select-clear {
    background-color: white;
}

.detailtache .ant-picker {
    border: none;
}

.detailtache .ant-input-number-input {
    border: none;
}

.detailtache .ant-input-number-input::placeholder {
    color: darkred;
}