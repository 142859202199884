/**
 * General variables
 */
/**
 * General configs
 */
 /* * { */
    /* box-sizing: border-box; } */
  
  /* body {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 1em; } */
  
  #root{
    overflow: hidden;
  }

  button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer; }
  
  Input {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0; }
    Input[type="submit"] {
      cursor: pointer; }
    Input::placeholder {
      font-size: .85rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: .1rem;
      color: rgb(211, 56, 56); }
  
  /**
   * Bounce to the left side
   */
  @keyframes bounceLeft {
    0% {
      transform: translate3d(100%, -50%, 0); }
    50% {
      transform: translate3d(-30px, -50%, 0); }
    100% {
      transform: translate3d(0, -50%, 0); } }
  
  /**
   * Bounce to the left side
   */
  @keyframes bounceRight {
    0% {
      transform: translate3d(0, -50%, 0); }
    50% {
      transform: translate3d(calc(100% + 30px), -50%, 0); }
    100% {
      transform: translate3d(100%, -50%, 0); } }
  
  /**
   * Show Sign Up form
   */
  @keyframes showSignUp {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0); } }
  
  /**
   * Page background
   */
  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #ccc;
    background-size: cover; }
    .user_options-container {
      position: relative;
      width: 80%; }
    .user_options-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: rgb(0, 21, 41);
      border-radius: 3px; }
  
  /**
   * Registered and Unregistered user box and text
   */
  .user_options-registered,
  .user_options-unregistered {
    width: 50%;
    padding: 23px 45px;
    color: #fff;
    font-weight: 300; }
  
  .user_registered-title,
  .user_unregistered-title {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.23rem;
    line-height: 1em; 
    color: #ccc;
    width: 400%;
    text-transform: uppercase;
  }
  
  .user_unregistered-text,
  .user_registered-text {
    font-size: .83rem;
    line-height: 1.8em;
    margin-bottom: 80px;
    text-align: justify;
   }
  
  .user_registered-login,
  .user_unregistered-signup {
    width: 180px;
    margin-top: 30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    /* text-transform: uppercase; */
    line-height: 1em;
    letter-spacing: .2rem;
    transition: background-color .2s ease-in-out, color .2s ease-in-out; }
    /* .user_registered-login:hover, */
    /* .user_unregistered-signup:hover {
      color: rgba(34, 34, 34, 0.85);
      background-color: #ccc; } */
  
  /**
   * Login and signup forms
   */
  .user_options-forms {
    position: absolute;
    top: 50%;
    left: 250px;
    width: calc(40% - 40px);
    min-height: 420px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transform: translate3d(100%, -50%, 0);
    transition: transform .4s ease-in-out; }
    .user_options-forms .user_forms-login {
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out; }
    .user_options-forms .forms_title {
      margin-bottom: 45px;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1em;
      text-transform: uppercase;
      color: #40a9ff;
      letter-spacing: .1rem; 
      font-weight: bold;
    }
    .user_options-forms .forms_field:not(:last-of-type) {
      margin-bottom: 20px; }
    .user_options-forms .forms_field-input {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding: 6px 20px 6px 6px;
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: gray;
      letter-spacing: .1rem;
      transition: border-color .2s ease-in-out; }
      .user_options-forms .forms_field-input:focus {
        border-color: gray; }
    .user_options-forms .forms_buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 35px; }
      .user_options-forms .forms_buttons-forgot {
        font-family: "Montserrat", sans-serif;
        letter-spacing: .1rem;
        color: #ccc;
        text-decoration: underline;
        transition: color .2s ease-in-out; }
        .user_options-forms .forms_buttons-forgot:hover {
          color: #b3b3b3; }
      .user_options-forms .forms_buttons-action {
        background-color: #e8716d;
        border-radius: 3px;
        padding: 10px 35px;
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: .1rem;
        transition: background-color .2s ease-in-out; }
        .user_options-forms .forms_buttons-action:hover {
          background-color: #e14641; }
    .user_options-forms .user_forms-signup,
    .user_options-forms .user_forms-login {
      position: absolute;
      top: 70px;
      left: 40px;
      width: calc(100% - 80px);
      opacity: 0;
      visibility: hidden;
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .5s ease-in-out; }
    .user_options-forms .user_forms-signup {
      transform: translate3d(120px, 0, 0); }
      .user_options-forms .user_forms-signup .forms_buttons {
        justify-content: flex-end; }
    .user_options-forms .user_forms-login {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible; }
  
  /**
   * Triggers
   */
  .user_options-forms.bounceLeft {
    animation: bounceLeft 1s forwards; }
    .user_options-forms.bounceLeft .user_forms-signup {
      animation: showSignUp 1s forwards; }
    .user_options-forms.bounceLeft .user_forms-login {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-120px, 0, 0); }
  
  .user_options-forms.bounceRight {
    animation: bounceRight 1s forwards; }
  
  /**
   * Responsive 990px
   */
  @media screen and (max-width: 990px) {
    .user_options-forms {
      min-height: 350px; }
      .user_options-forms .forms_buttons {
        flex-direction: column; }
      .user_options-forms .user_forms-login .forms_buttons-action {
        margin-top: 30px; }
      .user_options-forms .user_forms-signup,
      .user_options-forms .user_forms-login {
        top: 40px; }
    .user_options-registered,
    .user_options-unregistered {
      padding: 40px 45px; } }
  