.ant-popover-inner-content {
    width: 100px;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f1f1f1;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}

.table-striped-rows > td:first-child {
    padding: 110%;
}

/* className pour une cotation encours au client */
.etatDemande > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  #525252  !important; */
    padding: 2px;

}

.firstColumn > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  'red'  !important; */
    /* padding: 2px; */

}

/* class pour une cotation envoyer au client */
.sendClient1 > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  #fcc924  !important; */
    padding: 2px;


}

/* className pour une cotation refusé par un client */
.refusClient1 > td:first-child {
    /* background :rgb(252, 205, 201); */
    /* border-left: solid 5px  #fd310d  !important; */

}

/* className pour cotation valider par un client */
.validateClient1 > td:first-child {
    /* background: rgb(200, 248, 221); */
    /* border-left: solid 5px  #02ff52  !important; */

}

#swatch00 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fcc924;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch11 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fd310d;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch22 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #02ff52;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
