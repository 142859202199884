@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.lien {
    color: white;
    /* font-family: "Noto Sans", sans-serif; */
    /* font-size: 1.3em; */
    height: 50px;
    /* padding: 14px; */
    display: inline-block;
    /* display: flex; */
    padding-bottom: 25px;
    padding-left: 14px;
    padding-right: 14px;
    filter:alpha(opacity=0);
    -webkit-transition:200ms ease;
    -moz-transition:200ms ease;
    -o-transition:200ms ease;
    transition:200ms ease;
}

.lien:hover {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
    visibility:visible;
    opacity:1;
    filter:alpha(opacity=100);
}

.lienselected {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
}

.lienselected:hover {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
}

.menu {
    float: left;
    margin-right: 15px;
    height: 50px;
}

.avatar {
    float: right;
    margin-left: 30px;
}

.sous_lien {
    color: black;
    font-family: "Noto Sans", sans-serif;
    font-size: 1em;
    margin-top: 5;
    margin-bottom: 5;
}

.ant-page-header-heading-extra {
    margin: 0;
    height: 50px;
    line-height: 48px;
}

.ant-page-header-heading-title {
    color: white;
}

.notif {
    cursor: pointer;
    padding: 3px 3px;
    border-left: 3px solid #40a9ff;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    /* border-left: 1px solid rgb(240, 240, 240); */
    position: relative;
    margin-bottom: 5px;
}

.notif div.ant-typography, .ant-typography p {
    margin: 7px 0px;
}

.notif:hover div.ant-typography, .ant-typography p {
    margin: 7px 0px;
}

.notif:hover {
    background-color: #e6f7ff;
    cursor: pointer;
    padding: 3px 3px;
}
