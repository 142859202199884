.equipes .ant-card-body {
    padding: 12px;
}

.projet .ant-card-body {
    padding: 2px;
    padding-top: 10px;
}

.ant-card-meta-avatar {
    padding-right: 5px;
}

.ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .ajouter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .ajouter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 9px;
        margin-left: 9px;
        margin-bottom: 9px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 9px;
        margin-right: 9px;
        margin-bottom: 9px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}
