@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

body {
    margin:0;
    padding:0;
    width:100%;
    height: 100vh;
    /* background: #DEDEDE; */
    background:#dcdcdc;
}

.App {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans", sans-serif;
    /* background-color: #dcdcdc; */
    /* display: flex;
    flex-direction: column; */
}
