.profile .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #40a9ff;
    color: white;
    cursor: pointer;
}

.profile .ant-table-body {
    height: 62vh;
}

.profile .ant-table-placeholder {
    height: 62vh;
}

.profileSelected {
    background-color: #40a9ff;
    color: white;
}
