@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin:0;
    padding:0;
    width:100%;
    height: 100vh;
    /* background: #DEDEDE; */
    background:#dcdcdc;
}

.App {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans", sans-serif;
    /* background-color: #dcdcdc; */
    /* display: flex;
    flex-direction: column; */
}

/**
 * General variables
 */
/**
 * General configs
 */
 /* * { */
    /* box-sizing: border-box; } */
  
  /* body {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 1em; } */
  
  #root{
    overflow: hidden;
  }

  button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer; }
  
  Input {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0; }
    Input[type="submit"] {
      cursor: pointer; }
    Input::-webkit-input-placeholder {
      font-size: .85rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: .1rem;
      color: rgb(211, 56, 56); }
    Input:-ms-input-placeholder {
      font-size: .85rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: .1rem;
      color: rgb(211, 56, 56); }
    Input::placeholder {
      font-size: .85rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: .1rem;
      color: rgb(211, 56, 56); }
  
  /**
   * Bounce to the left side
   */
  @-webkit-keyframes bounceLeft {
    0% {
      -webkit-transform: translate3d(100%, -50%, 0);
              transform: translate3d(100%, -50%, 0); }
    50% {
      -webkit-transform: translate3d(-30px, -50%, 0);
              transform: translate3d(-30px, -50%, 0); }
    100% {
      -webkit-transform: translate3d(0, -50%, 0);
              transform: translate3d(0, -50%, 0); } }
  @keyframes bounceLeft {
    0% {
      -webkit-transform: translate3d(100%, -50%, 0);
              transform: translate3d(100%, -50%, 0); }
    50% {
      -webkit-transform: translate3d(-30px, -50%, 0);
              transform: translate3d(-30px, -50%, 0); }
    100% {
      -webkit-transform: translate3d(0, -50%, 0);
              transform: translate3d(0, -50%, 0); } }
  
  /**
   * Bounce to the left side
   */
  @-webkit-keyframes bounceRight {
    0% {
      -webkit-transform: translate3d(0, -50%, 0);
              transform: translate3d(0, -50%, 0); }
    50% {
      -webkit-transform: translate3d(calc(100% + 30px), -50%, 0);
              transform: translate3d(calc(100% + 30px), -50%, 0); }
    100% {
      -webkit-transform: translate3d(100%, -50%, 0);
              transform: translate3d(100%, -50%, 0); } }
  @keyframes bounceRight {
    0% {
      -webkit-transform: translate3d(0, -50%, 0);
              transform: translate3d(0, -50%, 0); }
    50% {
      -webkit-transform: translate3d(calc(100% + 30px), -50%, 0);
              transform: translate3d(calc(100% + 30px), -50%, 0); }
    100% {
      -webkit-transform: translate3d(100%, -50%, 0);
              transform: translate3d(100%, -50%, 0); } }
  
  /**
   * Show Sign Up form
   */
  @-webkit-keyframes showSignUp {
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  @keyframes showSignUp {
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  /**
   * Page background
   */
  .user {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100vh;
    background: #ccc;
    background-size: cover; }
    .user_options-container {
      position: relative;
      width: 80%; }
    .user_options-text {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      background-color: rgb(0, 21, 41);
      border-radius: 3px; }
  
  /**
   * Registered and Unregistered user box and text
   */
  .user_options-registered,
  .user_options-unregistered {
    width: 50%;
    padding: 23px 45px;
    color: #fff;
    font-weight: 300; }
  
  .user_registered-title,
  .user_unregistered-title {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.23rem;
    line-height: 1em; 
    color: #ccc;
    width: 400%;
    text-transform: uppercase;
  }
  
  .user_unregistered-text,
  .user_registered-text {
    font-size: .83rem;
    line-height: 1.8em;
    margin-bottom: 80px;
    text-align: justify;
   }
  
  .user_registered-login,
  .user_unregistered-signup {
    width: 180px;
    margin-top: 30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    /* text-transform: uppercase; */
    line-height: 1em;
    letter-spacing: .2rem;
    transition: background-color .2s ease-in-out, color .2s ease-in-out; }
    /* .user_registered-login:hover, */
    /* .user_unregistered-signup:hover {
      color: rgba(34, 34, 34, 0.85);
      background-color: #ccc; } */
  
  /**
   * Login and signup forms
   */
  .user_options-forms {
    position: absolute;
    top: 50%;
    left: 250px;
    width: calc(40% - 40px);
    min-height: 420px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    -webkit-transform: translate3d(100%, -50%, 0);
            transform: translate3d(100%, -50%, 0);
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
    .user_options-forms .user_forms-login {
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out; }
    .user_options-forms .forms_title {
      margin-bottom: 45px;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1em;
      text-transform: uppercase;
      color: #40a9ff;
      letter-spacing: .1rem; 
      font-weight: bold;
    }
    .user_options-forms .forms_field:not(:last-of-type) {
      margin-bottom: 20px; }
    .user_options-forms .forms_field-input {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding: 6px 20px 6px 6px;
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: gray;
      letter-spacing: .1rem;
      transition: border-color .2s ease-in-out; }
      .user_options-forms .forms_field-input:focus {
        border-color: gray; }
    .user_options-forms .forms_buttons {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 35px; }
      .user_options-forms .forms_buttons-forgot {
        font-family: "Montserrat", sans-serif;
        letter-spacing: .1rem;
        color: #ccc;
        text-decoration: underline;
        transition: color .2s ease-in-out; }
        .user_options-forms .forms_buttons-forgot:hover {
          color: #b3b3b3; }
      .user_options-forms .forms_buttons-action {
        background-color: #e8716d;
        border-radius: 3px;
        padding: 10px 35px;
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: .1rem;
        transition: background-color .2s ease-in-out; }
        .user_options-forms .forms_buttons-action:hover {
          background-color: #e14641; }
    .user_options-forms .user_forms-signup,
    .user_options-forms .user_forms-login {
      position: absolute;
      top: 70px;
      left: 40px;
      width: calc(100% - 80px);
      opacity: 0;
      visibility: hidden;
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out, -webkit-transform .5s ease-in-out;
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .5s ease-in-out;
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }
    .user_options-forms .user_forms-signup {
      -webkit-transform: translate3d(120px, 0, 0);
              transform: translate3d(120px, 0, 0); }
      .user_options-forms .user_forms-signup .forms_buttons {
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
    .user_options-forms .user_forms-login {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible; }
  
  /**
   * Triggers
   */
  .user_options-forms.bounceLeft {
    -webkit-animation: bounceLeft 1s forwards;
            animation: bounceLeft 1s forwards; }
    .user_options-forms.bounceLeft .user_forms-signup {
      -webkit-animation: showSignUp 1s forwards;
              animation: showSignUp 1s forwards; }
    .user_options-forms.bounceLeft .user_forms-login {
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translate3d(-120px, 0, 0);
              transform: translate3d(-120px, 0, 0); }
  
  .user_options-forms.bounceRight {
    -webkit-animation: bounceRight 1s forwards;
            animation: bounceRight 1s forwards; }
  
  /**
   * Responsive 990px
   */
  @media screen and (max-width: 990px) {
    .user_options-forms {
      min-height: 350px; }
      .user_options-forms .forms_buttons {
        -webkit-flex-direction: column;
                flex-direction: column; }
      .user_options-forms .user_forms-login .forms_buttons-action {
        margin-top: 30px; }
      .user_options-forms .user_forms-signup,
      .user_options-forms .user_forms-login {
        top: 40px; }
    .user_options-registered,
    .user_options-unregistered {
      padding: 40px 45px; } }
  
.centreVerticalement {
    display: inline-block;
    vertical-align: middle;
    float: none;
    width: 100;
    height: 100;
   }

.divProjet {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.divMenu {
    border-radius: 14,
}

/* .ant-tabs-tab {
    flex-grow: 1;
    margin-left: 63px;
    width: 100%;
    text-align: center;
} */
#tabs {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-left: 63px;
    width: 85%;
    text-align: center;
}

.lien {
    color: white;
    /* font-family: "Noto Sans", sans-serif; */
    /* font-size: 1.3em; */
    height: 50px;
    /* padding: 14px; */
    display: inline-block;
    /* display: flex; */
    padding-bottom: 25px;
    padding-left: 14px;
    padding-right: 14px;
    filter:alpha(opacity=0);
    transition:200ms ease;
}

.lien:hover {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
    visibility:visible;
    opacity:1;
    filter:alpha(opacity=100);
}

.lienselected {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
}

.lienselected:hover {
    color: white;
    display: inline-block;
    height: 50px;
    /* padding: 14px; */
    /* background-color: #40a9ff; */
    border-bottom: 3px solid #40a9ff;
    padding-left: 14px;
    padding-right: 14px;
    /* border-top: 2px solid white; */
}

.menu {
    float: left;
    margin-right: 15px;
    height: 50px;
}

.avatar {
    float: right;
    margin-left: 30px;
}

.sous_lien {
    color: black;
    font-family: "Noto Sans", sans-serif;
    font-size: 1em;
    margin-top: 5;
    margin-bottom: 5;
}

.ant-page-header-heading-extra {
    margin: 0;
    height: 50px;
    line-height: 48px;
}

.ant-page-header-heading-title {
    color: white;
}

.notif {
    cursor: pointer;
    padding: 3px 3px;
    border-left: 3px solid #40a9ff;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    /* border-left: 1px solid rgb(240, 240, 240); */
    position: relative;
    margin-bottom: 5px;
}

.notif div.ant-typography, .ant-typography p {
    margin: 7px 0px;
}

.notif:hover div.ant-typography, .ant-typography p {
    margin: 7px 0px;
}

.notif:hover {
    background-color: #e6f7ff;
    cursor: pointer;
    padding: 3px 3px;
}

.sous-tache {
    width: 250px;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.sous-tache:hover {
    background-color: #2f4f4f;
    width: 250px;
    border-radius: 10px;
    padding: 5px;
    color: white;
    margin-bottom: 5px;
    cursor: pointer;
}

.sous-tache:hover .btn {
    color: white;
}

.title {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    /* color */
}
.title:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
}

.responsable {
    margin: 2px;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.responsable:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.echecance {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.echecance:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.project {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.project:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.statut {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    border: 1px dashed #d3d3d3;
}
.statut:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
}

.description {
    margin: 2px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border: 1px dashed #d3d3d3;
}
.description:hover {
    border: 1px solid #e8e8eb;
    border-radius: 4px;
    background-color: #f8f8ff;
    padding: 5px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.detailtache .ant-picker-input > input {
    font-size: 13px;
}

.detailtache .ant-picker-input > input::-webkit-input-placeholder {
    color: darkred;
}

.detailtache .ant-picker-input > input:-ms-input-placeholder {
    color: darkred;
}

.detailtache .ant-picker-input > input::placeholder {
    color: darkred;
}

.detailtache .ant-input .placeholder {
    color: darkred;
}

.detailtache .ant-drawer-close:hover {
    color: white;
}

.detailtache .ant-drawer-close {
    color: white;
}

.detailtache .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
    border: none;
}

.detailtache .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background-color: white;
}

.detailtache .ant-select-clear {
    background-color: white;
}

.detailtache .ant-picker {
    border: none;
}

.detailtache .ant-input-number-input {
    border: none;
}

.detailtache .ant-input-number-input::-webkit-input-placeholder {
    color: darkred;
}

.detailtache .ant-input-number-input:-ms-input-placeholder {
    color: darkred;
}

.detailtache .ant-input-number-input::placeholder {
    color: darkred;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}
@media only screen and (max-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

.ant-ribbon.ant-ribbon-placement-end{
    right: 0px;
}
.ant-popover-inner-content {
    min-width: 250px;
}
.tab:hover {
    background-color: #2f4f4f;
    color: white;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding: 5px;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-content {
    background-color: #2f4f4f;
}

.ant-menu-submenu-selected {
    color: none;
}

/* .m:hover {
    border-right: 4px solid #40a9ff;
} */

.ant-menu-submenu-selected {
    color: rgb(67, 67, 67);
}

.ant-menu-submenu-selected:hover .ant-menu-submenu-active {
    color: rgb(67, 67, 67);
}

/* .ant-menu-submenu .ant-menu-submenu-active {
    color: green;
} */

.ant-menu:hover .ant-menu-light .ant-menu-root .ant-menu-inline {
    color: rgb(67, 67, 67);
}

.task :hover{
    background-color: rgb(241, 240, 240);
}

#filtre {
    background-color: antiquewhite;
    /* height: 50;
    width: 100; */
}

.center-div {
    margin: 0 auto;
    /* width: 100px;  */
}

.centreVerticalement {
    display: inline-block;
    vertical-align: middle;
    float: none;
   }

.i .anticon svg {
    width: 16px;
    height: 15px;
}
.liste .ant-typography {
    margin-left: 15px;
}
.utilisateur .ant-form-item {
    margin-bottom: 24px;
}

.utilisateur .ant-table-content {
    height: 60vh;
}

/* .ant-form-item {
    margin-bottom: 24px;
}

.ant-table-content {
    height: 490px;
} */

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f1f1f1;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}

.utilisateur .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #40a9ff;
    color: white;
    cursor: pointer;
}

.utilisateur .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    text-align: left;
}

/*.profile .test .ant-table-tbody > tr.ant-table-row > td {
    background-color: #40a9ff;
    color: white;
} */

.userSelected > td {
    color: white;
    background-color: #40a9ff;
}

/* .profile .userHover > td {
    color: white;
    background-color: #40a9ff;
} */

/* .profile tr.ant-table-row > td {
    background-color: red;
} */

.ant-input[disabled] {
    color: rgba(0, 21, 41, 0.52);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}
.container {
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 9px;
    padding-bottom: 0px;
}

.tab {
    cursor: pointer;
    color: #40a9ff;
}

.tab:hover {
    background-color: #40a9ff;
    color: white;
}

.tab:hover .tab1 {
    /* background-color: white; */
    color: white;
}

.tab .tab1 {
    /* background-color: white; */
    color: #40a9ff;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-card-head {
    font-size: 14px;
}

.profile .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #40a9ff;
    color: white;
    cursor: pointer;
}

.profile .ant-table-body {
    height: 62vh;
}

.profile .ant-table-placeholder {
    height: 62vh;
}

.profileSelected {
    background-color: #40a9ff;
    color: white;
}

.equipes .ant-card-body {
    padding: 12px;
}

.projet .ant-card-body {
    padding: 2px;
    padding-top: 10px;
}

.ant-card-meta-avatar {
    padding-right: 5px;
}

.ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .ajouter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .ajouter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 9%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ajouter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 9px;
        margin-left: 9px;
        margin-bottom: 9px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
    .affecter {
        width: 11%;
        text-align: center;
        height: 120px;
        margin-top: 9px;
        margin-right: 9px;
        margin-bottom: 9px;
        padding: 5px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }

    .ajouterSimple {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: #dedede;
        cursor: pointer;
    }
}

.voirtousprojet .ant-drawer-close:hover {
    color: black;
}

.voirtousprojet .ant-drawer-close {
    color: black;
}


.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    margin: 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.textarea {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
}

.ant-popover-inner-content {
    width: 100px;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f1f1f1;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}

.table-striped-rows > td:first-child {
    padding: 110%;
}

/* className pour une cotation encours au client */
.etatDemande > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  #525252  !important; */
    padding: 2px;

}

.firstColumn > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  'red'  !important; */
    /* padding: 2px; */

}

/* class pour une cotation envoyer au client */
.sendClient1 > td:first-child {
    /* background: rgb(245, 233, 223);  */
    /* border-left: solid 5px  #fcc924  !important; */
    padding: 2px;


}

/* className pour une cotation refusé par un client */
.refusClient1 > td:first-child {
    /* background :rgb(252, 205, 201); */
    /* border-left: solid 5px  #fd310d  !important; */

}

/* className pour cotation valider par un client */
.validateClient1 > td:first-child {
    /* background: rgb(200, 248, 221); */
    /* border-left: solid 5px  #02ff52  !important; */

}

#swatch00 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fcc924;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch11 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fd310d;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch22 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #02ff52;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}


.ant-popover-inner-content {
    width: 100px;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f1f1f1;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}


/* className pour une cotation encours au client */
.etatDemande > td:first-child {
    /* background: rgb(245, 233, 223);  */
    border-left: solid 5px  #525252  !important;

}

/* className pour une cotation envoyer au client */
.sendClient1 > td:first-child {
    /* background: rgb(245, 233, 223);  */
    border-left: solid 5px  #fcc924  !important;

}

/* className pour une cotation refusé par un client */
.refusClient1 > td:first-child {
    /* background :rgb(252, 205, 201); */
    border-left: solid 5px  #fd310d  !important;

}

/* className pour cotation valider par un client */
.validateClient1 > td:first-child {
    /* background: rgb(200, 248, 221); */
    border-left: solid 5px  #02ff52  !important;

}

#swatch00 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fcc924;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch11 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #fd310d;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

#swatch22 {
    margin-left: 20px;
    margin-top: 5px;
    background-color: #02ff52;
    width: 50px;
    height: 27px;
    border-radius: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

