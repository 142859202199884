.centreVerticalement {
    display: inline-block;
    vertical-align: middle;
    float: none;
    width: 100;
    height: 100;
   }

.divProjet {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.divMenu {
    border-radius: 14,
}

/* .ant-tabs-tab {
    flex-grow: 1;
    margin-left: 63px;
    width: 100%;
    text-align: center;
} */
#tabs {
    flex-grow: 1;
    margin-left: 63px;
    width: 85%;
    text-align: center;
}
