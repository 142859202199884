/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 18%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }

    .project-grid .ant-card-meta-title {
        font-size: 14px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 5px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .project-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .ajouter-grid {
        width: 22%;
        text-align: center;
        height: 120px;
        margin: 9px;
        padding: 5px;
        padding-top: 20px;
        border-radius: 3px;
        border: 2px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        cursor: pointer;
    }
}

.ant-ribbon.ant-ribbon-placement-end{
    right: 0px;
}