.utilisateur .ant-form-item {
    margin-bottom: 24px;
}

.utilisateur .ant-table-content {
    height: 60vh;
}

/* .ant-form-item {
    margin-bottom: 24px;
}

.ant-table-content {
    height: 490px;
} */

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f1f1f1;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}

.utilisateur .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #40a9ff;
    color: white;
    cursor: pointer;
}

.utilisateur .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    text-align: left;
}

/*.profile .test .ant-table-tbody > tr.ant-table-row > td {
    background-color: #40a9ff;
    color: white;
} */

.userSelected > td {
    color: white;
    background-color: #40a9ff;
}

/* .profile .userHover > td {
    color: white;
    background-color: #40a9ff;
} */

/* .profile tr.ant-table-row > td {
    background-color: red;
} */

.ant-input[disabled] {
    color: rgba(0, 21, 41, 0.52);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}