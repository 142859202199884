.container {
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 9px;
    padding-bottom: 0px;
}

.tab {
    cursor: pointer;
    color: #40a9ff;
}

.tab:hover {
    background-color: #40a9ff;
    color: white;
}

.tab:hover .tab1 {
    /* background-color: white; */
    color: white;
}

.tab .tab1 {
    /* background-color: white; */
    color: #40a9ff;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-card-head {
    font-size: 14px;
}
